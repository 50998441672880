import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { currentStatusData } from "../../data/currentStatusData";

import "./CurrentStatus.css";
import { camelize } from "../../utils";

function CurrentStatus() {
    const { theme } = useContext(ThemeContext);
    return (
        <>
            {currentStatusData.length > 0 && (
                <div id="currentStatus" className="currentStatus" style={{ backgroundColor: theme.primary }}>
                    <div className="currentStatus--header">
                        <h1 style={{ color: theme.secondary }}>Current Status</h1>
                    </div>
                    <div className="currentStatus--body">
                        <div className="currentStatus--slider" style={{ backgroundColor: theme.primary }}>
                            {currentStatusData.map((currentStatus) => (
                                <div
                                    className="single--currentStatus"
                                    key={currentStatus.id}
                                    style={{
                                        backgroundColor: theme.secondary,
                                    }}
                                    id={`${camelize("currentStatus" + currentStatus.name)}`}
                                >
                                    <div className="currentStatus--container">
                                        <div
                                            className="currentStatus--img"
                                            style={{
                                                borderColor: theme.secondary,
                                                backgroundColor: theme.primary,
                                                backgroundImage: `url(${currentStatus.image})`,
                                            }}
                                        />
                                        <div
                                            className="currentStatus--content"
                                            style={{
                                                color: theme.tertiary,
                                            }}
                                        >
                                            <h1>{currentStatus.name}</h1>
                                            <h4>{currentStatus.title}</h4>
                                            <p>{currentStatus.text}</p>
                                            {currentStatus.html && (
                                                <p
                                                    className="currentStatus--html"
                                                    dangerouslySetInnerHTML={{ __html: currentStatus.html }}
                                                ></p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default CurrentStatus;
