export const skillsFrontendData = {
    title: "frontend",
    list: [
        "React",
        "javascript",
        "Typescript",
        "es6",
        "ES Lint",
        "CSS",
        "Sass",
        "storybook",
        "Jest",
        "React Testing Library",
        "HTML",
        "Graphql",
        "Google Tag Manager",
    ],
};
export const skillsNativeData = {
    title: "native",
    list: ["Xcode", "Android Studio", "Native Bridging"],
};

export const skillsOperationalData = {
    title: "Platform & Tooling",
    list: [
        "K8s",
        "Docker",
        "Instana",
        "Grafana",
        "Kibana",
        "Bash scripting",
        "AWS",
        "nodejs",
        "Express server",
        "Logstash",
        "helm",
    ],
};

export const skillsBackendData = {
    title: "backend",
    list: ["Java", "Spring", "Kafka", "Maria DB", "MySQL"],
};
export const skillsToolsData = {
    title: "tools",
    list: ["Git", "Jira", "confluence", "agile", "Jenkins", "Zeplin", "Sonar", "Slack", "Gimp", "Redux dev tools"],
};
