import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import { hobbiesData } from "../../data/hobbiesData";

import "./Hobbies.css";

function Hobbies() {
    const { theme } = useContext(ThemeContext);
    return (
        <>
            {hobbiesData.length > 0 && (
                <div id="hobbies" className="hobbies" style={{ backgroundColor: theme.primary }}>
                    <div className="hobbies--header">
                        <h1 style={{ color: theme.secondary }}>Hobbies</h1>
                    </div>
                    <div className="hobbies--body">
                        <div className="hobbies--slider" style={{ backgroundColor: theme.primary }}>
                            {hobbiesData.map((hobby) => (
                                <div className="single--hobby" key={hobby.id}>
                                    <div className="hobbies--container">
                                        <div
                                            className="hobby--img"
                                            style={{
                                                backgroundColor: theme.secondary,
                                            }}
                                        >
                                            {hobby.srcset ? (
                                                <img srcSet={hobby.srcset} src={hobby.image} alt={hobby.name} />
                                            ) : (
                                                <img src={hobby.image} alt={hobby.name} />
                                            )}
                                        </div>
                                        <div
                                            className="hobby--content"
                                            style={{
                                                backgroundColor: theme.secondary,
                                                color: theme.tertiary,
                                            }}
                                        >
                                            <h1>{hobby.name}</h1>
                                            <h4>{hobby.title}</h4>
                                            <p>{hobby.text}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Hobbies;
