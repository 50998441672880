import React from "react";
import { Helmet } from "react-helmet";

import {
    Navbar,
    Footer,
    Landing,
    About,
    Skills,
    Testimonials,
    Education,
    Experience,
    Contacts,
    Achievement,
    Projects,
    Hobbies,
    CurrentStatus,
} from "../../components";
import { headerData } from "../../data/headerData";

function Main() {
    return (
        <div>
            <Helmet>
                <title>{headerData.name} - Porfolio</title>
            </Helmet>

            <Navbar />
            <Landing />
            <About />
            <Experience />
            <Education />
            <Skills />
            <Testimonials />
            <Projects />
            <Hobbies />
            <Achievement />

            {/*<Services />*/}
            {/*<Blog />*/}
            <CurrentStatus />
            <Contacts />
            <Footer />
        </div>
    );
}

export default Main;
