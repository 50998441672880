import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

import { ThemeContext } from "../../contexts/ThemeContext";

import expImgWhite from "../../assets/svg/experience/expImgWhite.svg";
import expImgBlack from "../../assets/svg/experience/expImgBlack.svg";

import "./Experience.css";
import { timer } from "../../utils/Timer";

function ExperienceCard({ id, company, jobtitle, startYear, endYear, industry, image }) {
    const { theme } = useContext(ThemeContext);
    const [localEndYear, setLocalEndYear] = useState("");

    useEffect(() => {
        if (endYear === "now") {
            timer(setLocalEndYear);
        } else {
            setLocalEndYear(endYear);
        }
    }, [endYear]);

    const useStyles = makeStyles((t) => ({
        experienceCard: {
            backgroundColor: theme.primary30,
            "&:hover": {
                backgroundColor: theme.primary50,
            },
        },
    }));
    const classes = useStyles();

    return (
        <Fade bottom>
            <div key={id} className={`experience-card ${classes.experienceCard}`}>
                <div className="expcard-img" style={{ backgroundColor: theme.primary }}>
                    {image ? (
                        <img src={image} alt={company} />
                    ) : (
                        <img src={theme.type === "light" ? expImgBlack : expImgWhite} alt="" />
                    )}
                </div>
                <div className="experience-details">
                    <h6 style={{ color: theme.tertiary }}>
                        <span>{startYear}</span> - <span>{localEndYear}</span>
                    </h6>
                    <h4 style={{ color: theme.tertiary }}>{jobtitle}</h4>
                    <h5 style={{ color: theme.tertiary80 }}>{company}</h5>
                    <span style={{ color: theme.tertiary50 }}>{industry}</span>
                </div>
            </div>
        </Fade>
    );
}

export default ExperienceCard;
