export const socialsData = {
    github: "https://github.com/philipvella",
    facebook: "https://www.facebook.com/vella1990/",
    linkedIn: "https://www.linkedin.com/in/philip-v-52190577",
    instagram: "",
    codepen: "",
    twitter: "",
    reddit: "",
    blogger: "",
    medium: "",
    stackOverflow: "",
    gitlab: "",
    youtube: "",
};
