let interval;
export const timer = (setTimer) => {
    const currentTime = new Date();
    const date = currentTime.toDateString();
    const hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();
    let sec = currentTime.getSeconds();
    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (sec < 10) {
        sec = "0" + sec;
    }
    if (!interval) {
        interval = setInterval(() => timer(setTimer), 1000);
    }
    setTimer && setTimer(`${date} ${hours}:${minutes}:${sec} ${hours > 11 ? "PM" : "AM"}`);
};
