import ukPin from "../assets/svg/currentStatus/ukPin.svg";
import homeOffice from "../assets/svg/currentStatus/homeoffice.svg";

export const currentStatusData = [
    {
        id: 2,
        name: "Employment Setup Preference",
        title: "Hybrid",
        text: "During the COVID19 pandemic I was introduced to remote working, to which I got very much accustomed to. It has made me aware of how important it is to leave reactions on Slack when a message is read, and how important it is to be precise and concise with written communication. I am a very sociable person and thus I enjoy going to the office once to two-times a week. This helps me connect and bond more with my team and colleagues.",
        image: homeOffice,
    },
    {
        id: 1,
        name: "Living",
        title: "Based in London (UK)",
        text: "I am currently based in London (UK), working as a Lead Software Engineer for Relevant Bits Inc (London), mainly focused on Frontend Web-Development and NodeJs backend systems.",
        image: ukPin,
    },
];
