import gettingStartedWithSpringBoot from "../assets/jpg/achievements/certificate-of-completion-for-getting-started-with-spring-boot.jpg";

export const achievementData = {
    bio: "\"The future rewards those who press on. I don't have time to feel sorry for myself. I don't have time to complain. I'm going to press on\" - Barack Obama",
    achievements: [
        {
            id: 1,
            title: "2021 Certificate of Completion - Spring Boot",
            details: "Getting started with Spring Boot",
            date: "Aug 14, 2021",
            field: "Coding",
            image: gettingStartedWithSpringBoot,
        },
    ],
};
