import overlayBetSlip from "../assets/jpg/projects/overlayBetSlip.jpeg";
import missingDetails from "../assets/jpg/projects/missingDetails.jpg";

export const projectsData = [
    {
        id: 1,
        projectName: "Overlay Bet Slip",
        projectDesc:
            "Migrated from full Page view to an overlay style bet slip. This way customers can use the betslip on a mobile phone without losing the focus of their current markets",
        tags: ["ES6", "REACT", "REDUX", "HTML", "CSS"],
        demo: "https://tipico.com/",
        image: overlayBetSlip,
    },
    {
        id: 2,
        projectName: "Missing Details Screen",
        projectDesc:
            "In order to obtain proper KYC status, I created a dynamic screen which is persisting on Tipico's app in order to force the user to enter missing data which was essential to keep compliant to regulator's requirements ",
        tags: ["ES6", "REACT", "REDUX", "HTML", "CSS"],
        demo: "https://tipico.com/",
        image: missingDetails,
    },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
