import philipSvg from "../assets/svg/about/code-typing-concept-illustration.svg";

export const aboutData = {
    title: "ABOUT",
    description: [
        "Hello, I'm Philip, a web developer based in London, UK. With nine years of experience in software development, I specialize in React frontend web development and Node.js backend. Currently, I hold the position of Lead Software Engineer at Relevant Bits Inc. where I am deeply passionate about creating safe and engaging user experiences.",
        "Constantly driven by my love for software, I approach every project with a research-oriented mindset, constantly experimenting and iterating to find the best possible solutions. My relentless pursuit of scalability, high availability, and robustness has also led me to explore full stack development, including frontend, backend, networking, and tools like Jenkins, Docker, and Kubernetes.",
        "I embrace an open and proactive approach to every task entrusted to me, leveraging my strong analytical and problem-solving skills to consistently deliver high-quality work. Additionally, my flexibility and creativity have contributed to taking my teams to new levels of performance.",
        "As part of a self-organized team, I work closely with product managers to develop mobile and desktop features. My ultimate goal is to continuously enhance our product, making it smarter, simpler, and safer for users.",
        "Throughout my career, I have honed my expertise in several technologies, including Javascript ES6, React, Redux, Typescript, Sass, and Jest, alongside various frontend libraries and programming languages. Furthermore, I actively support backend developers under pressure and tight deadlines by developing new features across the entire stack, from frontend to backend and OPS.",
        "Driven by my passion for this field, I also enjoy coaching and mentoring junior team members, firmly believing in the power of knowledge sharing and mutual learning.",
    ],
    image: philipSvg,
};
