import { commonData } from "./commonData";

export const blogData = [
    {
        id: 1,
        title: "NO LONGER AVAILABLE",
        description: "Mosta Corner Penthouse: No Longer Available",
        date: "29/06/2023",
        image: commonData.mostaCover,
        url: commonData.mostaPdf,
    },
];

