import React, { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";
import work from "../../assets/svg/experience/work.svg";
import "./Experience.css";

import { experienceData } from "../../data/experienceData";
import ExperienceCard from "./ExperienceCard";

function Experience() {
    const { theme } = useContext(ThemeContext);
    return (
        <div className="experience" id="experience" style={{ backgroundColor: theme.secondary }}>
            <div className="experience-body">
                <div className="experience-image">
                    <img src={work} alt="" />
                </div>
                <div className="experience-description">
                    <h1 style={{ color: theme.primary }}>Experience</h1>
                    {experienceData.map((exp) => (
                        <ExperienceCard
                            key={exp.id}
                            id={exp.id}
                            jobtitle={exp.jobtitle}
                            company={exp.company}
                            industry={exp.industry}
                            startYear={exp.startYear}
                            endYear={exp.endYear}
                            image={exp.image}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Experience;
