export const educationData = [
    {
        id: 1,
        institution: "Fraunhofer Gesellschaft University (Germany)",
        course: "B.A. - Software Development",
        startYear: "2012",
        endYear: "2013",
    },
    {
        id: 2,
        institution: "MCAST IICT, Paola (Malta) ",
        course: "BTEC Higher National Diploma - Computing",
        startYear: "2010",
        endYear: "2012",
    },
    {
        id: 3,
        institution: "MCAST IICT, Paola (Malta) ",
        course: "BTEC National Diploma - Computing",
        startYear: "2008",
        endYear: "2010",
    },
];
