import React, { useContext, useState } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";
import Fade from "react-reveal/Fade";
import { IoMenuSharp, IoHomeSharp } from "react-icons/io5";
import { HiDocumentText } from "react-icons/hi";
import { BsFillGearFill } from "react-icons/bs";
import { VscFilePdf } from "react-icons/vsc";
import { MdInfoOutline, MdPhone } from "react-icons/md";
import { IoDocumentText } from "react-icons/io5";
import { CgWorkAlt } from "react-icons/cg";
import { FaUser, FaFolderOpen, FaQuoteLeft, FaRunning } from "react-icons/fa";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CloseIcon from "@material-ui/icons/Close";

import "./Navbar.css";
import { headerData } from "../../data/headerData";
import { ThemeContext } from "../../contexts/ThemeContext";
import { commonData } from "../../data/commonData";

function Navbar() {
    const { theme, setHandleDrawer } = useContext(ThemeContext);

    const [open, setOpen] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
        setHandleDrawer();
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setHandleDrawer();
    };

    const useStyles = makeStyles((t) => ({
        navMenu: {
            fontSize: "2.5rem",
            color: theme.tertiary,
            cursor: "pointer",
            transform: "translateY(-10px)",
            transition: "color 0.3s",
            position: "fixed",
            top: "30px",
            right: "30px",
            "&:hover": {
                color: theme.primary,
            },
            [t.breakpoints.down("sm")]: {
                fontSize: "2.5rem",
            },
            [t.breakpoints.down("xs")]: {
                fontSize: "2rem",
            },
        },
        MuiDrawer: {
            padding: "0em 1em",
            width: "14em",
            fontFamily: " var(--primaryFont)",
            fontStyle: " normal",
            fontWeight: " normal",
            fontSize: " 24px",
            background: theme.secondary,
            overflow: "scroll",
            borderTopRightRadius: "2px",
            borderBottomRightRadius: "2px",
            [t.breakpoints.down("sm")]: {
                width: "12em",
            },
        },
        closebtnIcon: {
            fontSize: "2rem",
            fontWeight: "bold",
            cursor: "pointer",
            color: theme.primary,
            position: "absolute",
            right: 40,
            top: 40,
            transition: "color 0.2s",
            "&:hover": {
                color: theme.tertiary,
            },
            [t.breakpoints.down("sm")]: {
                right: 20,
                top: 20,
            },
        },
        drawerItem: {
            margin: "1rem auto",
            borderRadius: "0",
            background: theme.secondary,
            color: theme.primary,
            width: "95%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            padding: "0 10px",
            boxSizing: "border-box",
            borderColor: theme.primary,
            transition: "background-color 0.2s, color 0.2s",
            "&:hover": {
                color: theme.tertiary50,
            },
            "&:hover *": {
                color: theme.tertiary50,
            },
            [t.breakpoints.down("sm")]: {
                width: "100%",
                height: "30px",
            },
        },
        drawerLinks: {
            fontFamily: "var(--primaryFont)",
            fontSize: "1.3rem",
            paddingLeft: "10px",
            fontWeight: 500,
            textTransform: "uppercase",
            [t.breakpoints.down("sm")]: {
                fontSize: "1.125rem",
            },
        },
        drawerIcon: {
            fontSize: "2rem",
            background: "#e9ad35",
            padding: "6px",
            borderRadius: "100%",
            color: "white",
        },
    }));

    const classes = useStyles();

    const shortname = (name) => {
        if (name.length > 12) {
            return name.split(" ")[0];
        } else {
            return name;
        }
    };

    const linkData = [
        {
            key: 1,
            to: "/",
            icon: <IoHomeSharp className={classes.drawerIcon} />,
            text: "Home",
        },
        {
            key: 11,
            to: "",
            onClick: () => (window.location.href = commonData.resumePdf),
            icon: <VscFilePdf className={classes.drawerIcon} />,
            text: "Download CV",
        },
        {
            key: 2,
            to: "/#about",
            icon: <FaUser className={classes.drawerIcon} />,
            text: "About",
        },
        {
            key: 7,
            to: "/#experience",
            icon: <CgWorkAlt className={classes.drawerIcon} />,
            text: "Experience",
        },
        {
            key: 3,
            to: "/#education",
            icon: <HiDocumentText className={classes.drawerIcon} />,
            text: "Education",
        },
        {
            key: 4,
            to: "/#skills",
            icon: <BsFillGearFill className={classes.drawerIcon} />,
            text: "Skills",
        },
        {
            key: 8,
            to: "/#testimonials",
            icon: <FaQuoteLeft className={classes.drawerIcon} />,
            text: "Testimonials",
        },
        {
            key: 5,
            to: "/#projects",
            icon: <FaFolderOpen className={classes.drawerIcon} />,
            text: "Projects",
        },
        {
            key: 9,
            to: "/#hobbies",
            icon: <FaRunning className={classes.drawerIcon} />,
            text: "Hobbies",
        },
        {
            key: 10,
            to: "/#currentStatus",
            icon: <MdInfoOutline className={classes.drawerIcon} />,
            text: "Current Status",
        },
        {
            key: 6,
            to: "/#contacts",
            icon: <MdPhone className={classes.drawerIcon} />,
            text: "Contact",
        },
        {
            key: 12,
            to: "/blog",
            icon: <IoDocumentText className={classes.drawerIcon} />,
            text: "Blog",
        },
    ];

    return (
        <div className="navbar">
            <div className="navbar--container">
                <h1 style={{ color: theme.secondary }}>{shortname(headerData.name)}</h1>

                <IoMenuSharp className={classes.navMenu} onClick={handleDrawerOpen} aria-label="Menu" id="navicon" />
            </div>
            <Drawer
                variant="temporary"
                onClose={(event, reason) => {
                    if (reason !== "backdropClick") {
                        handleDrawerClose();
                    } else if (reason !== "escapeKeyDown") {
                        handleDrawerClose();
                    }
                }}
                anchor="left"
                open={open}
                classes={{ paper: classes.MuiDrawer }}
                className="drawer"
                disableScrollLock={true}
            >
                <div className="div-closebtn">
                    <CloseIcon
                        onClick={handleDrawerClose}
                        onKeyDown={(e) => {
                            if (e.key === " " || e.key === "Enter") {
                                e.preventDefault();
                                handleDrawerClose();
                            }
                        }}
                        className={classes.closebtnIcon}
                        role="button"
                        tabIndex="0"
                        aria-label="Close"
                    />
                </div>
                <br />

                <div onClick={handleDrawerClose}>
                    <div className="navLink--container">
                        {linkData.map((item, key) => (
                            <Fade left key={`nav-bar-item-${key}-fade`}>
                                <NavLink
                                    key={item.key}
                                    to={item.to}
                                    smooth={true}
                                    onClick={item.onClick ? item.onClick : () => {}}
                                    spy="true"
                                    duration={2000}
                                >
                                    <div className={classes.drawerItem}>
                                        {item.icon}
                                        <span className={classes.drawerLinks}>{item.text}</span>
                                    </div>
                                </NavLink>
                            </Fade>
                        ))}
                    </div>
                </div>
            </Drawer>
        </div>
    );
}

export default Navbar;
