import tipico from "../assets/svg/experience/tipico.svg";
import rb from "../assets/svg/experience/relevantBits.svg";
import mita from "../assets/svg/experience/mita.svg";
import b3w from "../assets/svg/experience/b3w.svg";

export const experienceData = [
    {
        id: 1,
        company: "Relevant Bits Inc (London)",
        industry: "B2B Software Development",
        jobtitle: "Lead Software Engineer",
        startYear: "2022",
        endYear: "now",
        image: rb,
    },
    {
        id: 2,
        company: "Tipico Co. Ltd, St. Julian’s (Malta)",
        industry: "iGaming / Sportsbook",
        jobtitle: "Software Developer",
        startYear: "2016",
        endYear: "2022",
        image: tipico,
    },
    {
        id: 3,
        company: "B3W Group - B3W Group, Gzira (Malta)",
        industry: "iGaming / Online Casino",
        jobtitle: "Software Developer",
        startYear: "2015",
        endYear: "2016",
        image: b3w,
    },
    {
        id: 4,
        company: "B3W Group - B3W Group, Gzira (Malta)",
        industry: "iGaming / Online Casino",
        jobtitle: "Front End Software Developer",
        startYear: "2013",
        endYear: "2015",
        image: b3w,
    },
    {
        id: 5,
        company: "MITA, Santa Venera (Malta)",
        industry: "Digital Security Consultant for the Government of Malta",
        jobtitle: "Web Deployment and Quality Assurance",
        startYear: "2012",
        endYear: "2013",
        image: mita,
    },
];
