import React, { useContext } from "react";

import "./About.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { aboutData } from "../../data/aboutData";
import developer from "../../assets/svg/about/developer.svg";

function About() {
    const { theme } = useContext(ThemeContext);
    return (
        <div className="about" id="about" style={{ backgroundColor: theme.secondary }}>
            <div className="line-styling">
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-circle" style={{ backgroundColor: theme.primary }}></div>
                <div className="style-line" style={{ backgroundColor: theme.primary }}></div>
            </div>
            <div className="about-body">
                <div className="about-description">
                    <h2 style={{ color: theme.primary }}>{aboutData.title}</h2>
                    {aboutData.description.map((item, key) => (
                        <p key={`item-${key}`} style={{ color: theme.tertiary80 }}>
                            {item}
                        </p>
                    ))}
                </div>
                <div className="about-img">
                    <img src={developer ? developer : aboutData.image} alt="" />
                </div>
            </div>
        </div>
    );
}

export default About;
